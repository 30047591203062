<template>
    <a-layout>
        <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible>
            <mymenu />
        </a-layout-sider>
        <a-layout>
            <a-layout-content :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px' }">
                <div class="aa">
                    <a-form>
                        <h1 v-for="all_one, a_i in all_datas" :key="a_i">
                            <font href="#" @click="select = a_i">{{ a_i }}</font>
                            <b v-if="a_i == select">·</b>
                        </h1>
                        <!-- {{select}} -->
                        <a-form-item>
                            <div
                                style="width: 300px;height: 168px;overflow-y:auto;overflow-x:hidden;background-color: aliceblue;">
                                <ul v-for="item, i in all_datas[select]" :key="i" style="margin: 0px;">
                                    <li style="margin: 0px;">{{ item['phone'].slice(0, 8) }}:&nbsp;{{ item['value'] }}
                                    </li>
                                </ul>
                            </div>
                            <a-input v-model:value="axx" placeholder="请输入特定的json数据"
                                style="width: 72%; margin-right: 8px" @@keyup.enter.native="send_wb" />
                            <a-button type="primary" html-type="submit" @click="send_wb">Submit</a-button>
                        </a-form-item>
                    </a-form>
                </div>

            </a-layout-content>
        </a-layout>
    </a-layout>
</template>

<script>
import menu from "./menu.vue";
export default {
    components: {
        mymenu: menu
    },
    data() {
        return {
            key: "value",
            memu_list: [],
            axx: '',
            collapsed: '',
            selectedKeys: [''],
            all_datas: { 7: [{ "phone": 'ab', "value": '123' }, { "phone": 'db', 'value': '456' }], 3: [{ "phone": 'abc', "value": '1234' }, { "phone": 'dbe', 'value': '5678' }] },
            select: 3,
            ws:"",
        }
    },
    methods: {
        init_websocket() {
              if ("WebSocket" in window) {
                alert("lianjie")
                this.ws = new WebSocket('ws://localhost:8001/websocket/?token=' + localStorage.getItem('token'))

                this.ws.onopen = this.open_websocket
                this.ws.onmessage = this.listen_wb
                this.ws.onerror = this.err_wb
                this.ws.onclose = this.close_wb
            }else{
                console.log("你tm的浏览器垃圾");
            }
        },
        err_wb() {
            this.lock = false;
            this.reconnect();
        },
        open_websocket() {
            this.lock = true
            console.log(this.ws, typeof(this.ws))
            // console.log("连接已打开", this.wb.readyState);
        },
        listen_wb(e) {
            console.log("message===>", e);
            // data = JSON.parse(e)
        },

        send_wb() {
            console.log(this.ws.readyState);
            // this.all_datas[this.select].push({ 'phone': localStorage.getItem('phone'), "value": this.axx })
            // this.axx = {"char":"消息","to_id":""}
            // // this.ws.send(JSON.stringify(this.axx))
            this.ws.send("hello")
            // this.axx = ""
        },

        close_wb() {
            this.$message.info("宕机了惹, 正在重新链接(Ω_Ω)");
            // this.get_list();
            this.reconnect();
        },

        // addtalk() {
        //     this.all_datas[this.select].push({ 'phone': localStorage.getItem('phone'), "value": this.axx })
        //     this.ws.send(this.axx)
        //     this.axx = ""

        // },
        reconnect() {
            var that = this
            console.log('再次连接');
            if (that.lock == true) {
                return
            }
            that.lock = true;
            setTimeout(function () {
                that.get_ws();
                that.lock = false
            }, 500)
        },
        get_ws() {
            var thatc = this
            this.ws = new WebSocket('ws://127.0.0.1:8000/ws/')
            this.ws.onopen = function () {
                // this.ws.send(JSON.stringify({ "token": localStorage.getItem('token') }))
                console.log("建立连接");
            }
            this.ws.onmessage = function (evt) {
                console.log(evt)
                thatc.$message.info(evt.data)
                thatc.myaxios('http:/127.0.0.1:8000/suremsg/')
            }
            this.ws.onclose = function () {
                console.log("连接关闭");
                thatc.reconnect();
            }
            // this.ws.send("123")
            // this.ws.onopen
        },
    },
    mounted() {
        this.init_websocket()
    },
    created() { }
}
</script>

<style scoped>
@import url('../assets/style.css');

.aa {
    position: absolute;
    float: right;
    margin-top: 100px;
    margin-left: 900px;
    /* background-color: rgba(250, 235, 215, 0); */
    width: 300px;
    height: 200px;
}
</style>